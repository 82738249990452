<template>
    <div>
        <TableList :columns="columns" :get_table_list="this.get_table_list" :form_data_seo="form_data_seo"
            :slot_table_list="['operation','status']" ref="list" :rowKey="'id'" >
            <template slot="left_btn">
                <a-button @click="getAddSave('','add')" type="primary" style="margin-left: 15px;">新增试验类型</a-button>
            </template>
            <template slot="status" slot-scope="data">
                <span v-if="data.record.status == 1" class="status_box" @click="getMaterialStatus(data.record)">启用</span>
                <span v-else class="status_box" style="background:red;" @click="getMaterialStatus(data.record)">禁用</span>
            </template>
            <template slot="operation" slot-scope="data">
                <a @click="getAddSave(data.record,'edit')">编辑</a>
                <a-divider type="vertical" />
                <a @click="getDelete(data.record)">删除</a>
            </template>
        </TableList>

        <!-- 添加或编辑工厂 -->
        <a-modal v-model:visible="testTypeShow" :title="testTypeFrom.id ? '编辑试验类型信息' : '新增试验类型信息'" @ok="testTypeOk">
            <a-form :label-col="labelColEdit" :wrapper-col="wrapperColEdit">
                <a-form-item required name='name' label="试验类型名称" :rules="[{ required: true, message: '试验类型名称不能为空',tagger:'blur' }]">
                    <a-input v-model:value="testTypeFrom.name" placeholder="请输入试验类型名称" />
                </a-form-item>
                <a-form-item name='name' label="试验类型描述">
                    <a-textarea v-model:value="testTypeFrom.describe" placeholder="请输入试验类型描述" />
                </a-form-item>
            </a-form>
        </a-modal>

    </div>
</template>
<script>
import Form from "@/components/Form"
import TableList from "@/components/TableList"
import {message,Modal} from 'ant-design-vue'
import {getExperimentTypeList,saveExperimentDetail,delExperimentTypeInfo,changeExperimentType} from '@/api/qms.js'
const columns = [{
        title: "试验类型名称",
        width:300,
        dataIndex: "name",
    },
    {
        title: "试验类型描述",
        width:300,
        dataIndex: "describe",
    },
    {
        title: "状态",
        dataIndex: "status",
        scopedSlots: {
            customRender: "status"
        }
    },
    {
        title: "创建时间",
        dataIndex: "create_time",
    },
    {
        title: "操作",
        scopedSlots: {
            customRender: "operation"
        }
    }
];

export default {
    name: "Index",
    components: {
        Form,
        TableList
    },
    data() {
        return {
            get_table_list: getExperimentTypeList,
            columns,
            form_data_seo: {
                ...this.$config.form_data_seo,
                list: [
                    {
                        type: "text",
                        name: "keyword",
                        title: "关键词",
                        placeholder: "请输入试验类型名称",
                        options: {}
                    },
                ],
            },
            testTypeShow:false,
            testTypeFrom:{
                id:'',
                name:'',
                describe:'',
            },

            labelColEdit: {
                span: 6
            },
            wrapperColEdit: {
                span: 16
            },

        };
    },
    created() {

    },
    methods: {
        getAddSave(info,type){
            //新增
            if(type == 'add'){
                this.testTypeFrom = {}
                this.testTypeShow = true
            }
            //编辑数据
            if(type == 'edit'){
                this.testTypeFrom.id = info.id
                this.testTypeFrom.name = info.name
                this.testTypeFrom.describe = info.describe
                this.testTypeShow = true
            }
        },

        testTypeOk(){
            saveExperimentDetail({
                ...this.testTypeFrom
            }).then(res=>{
                this.testTypeShow = false
                message.success('保存成功');
                this.$refs.list.get_list()
            })
        },

        getDelete(info){
            let that = this
            Modal.confirm({
                title: '温馨提示',
                content: '确定要删除试验类型【'+info.name+'】吗？',
                okText: '确定',
                cancelText: '取消',
                onOk() {
                    delExperimentTypeInfo({
                        id:info.id
                    }).then(res=>{
                        message.success('删除成功');
                        that.$refs.list.get_list()
                    })
                }
            });
        },

        getMaterialStatus(info){
            let text = info.status == 1 ? "禁用" : "启用"
            let that = this
            Modal.confirm({
                title: '温馨提示',
                content: '确定要'+text+'试验类型【'+info.name+'】吗？',
                okText: '确定',
                cancelText: '取消',
                onOk() {
                    changeExperimentType({
                        id:info.id
                    }).then(res=>{
                        message.success('操作成功');
                        that.$refs.list.get_list()
                    })
                }
            });
        },
    }
}
</script>

<style lang="less" scoped>
.status_box{
    background:#1890ff;
    padding:1px 5px 3px 5px;
    color:#ffffff;
    border-radius:2px;
    font-size:12px;
    cursor:pointer;
    display:inline-block;
    min-width:34px;
}
</style>
